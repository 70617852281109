.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.div_container{
  flex: 1;
  width: 100%;
}
.container1{
  flex: 1;
  width: 100%;
}

.inputs_form{
  margin: 10px;
  margin-left: 100px;
  margin-right: 100px;
  padding: 20px;
  display:flex;
  flex-direction:column;
  justify-content: center;
}

.div_form{
  width: 30%;
}
.select_languages{
  
  background-color: #282c34;
  color: #282c34;
}


.perfundo_button{
  margin-top: 10px;
}

.conversation_div{
  border-style: groove;
}

.add_button{

  height: 50px;
}

._input{
  height: 40px;
}